import React from "react";
import Icon, { IconProps } from "./Icon";
import { getNumericRotate } from "./utils";

export function MinimizeLeft({ viewBox = "0 0 16 16", size, ...iconProps }: IconProps) {
  return (
    <Icon width={size} height={size} fill="none" viewBox={viewBox} {...iconProps}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M6.5 4l-4 4 4 4M12.5 4l-4 4 4 4"
      />
    </Icon>
  );
}

export function MinimizeRight(props: IconProps) {
  const totalRotate = 180 + getNumericRotate(props.rotate);

  return <MinimizeLeft {...props} rotate={totalRotate} />;
}
