import React, { ComponentPropsWithRef } from "react";
import * as Stitches from "@stitches/react";

import { UnstyledButton } from "@puzzle/ui";
import { styled } from "@puzzle/theme";
import { MinimizeRight, MinimizeLeft } from "@puzzle/icons";

const SidebarToggleRoot = styled(UnstyledButton, {
  padding: "$1",
  lineHeight: 0,
  borderRadius: "$ellipse",
  transition: "background 0.15s ease-out",
  color: "$gray500",

  "&:hover, &:focus": {
    backgroundColor: "rgba(248, 248, 250, 0.04)",
    color: "$gray300",
  },
});

export const SidebarToggle = (
  props: ComponentPropsWithRef<"button"> &
    Stitches.VariantProps<typeof SidebarToggleRoot> &
    Stitches.CSS
) => {
  return (
    <SidebarToggleRoot {...props}>
      {props.open ? <MinimizeLeft /> : <MinimizeRight />}
    </SidebarToggleRoot>
  );
};
