import { useCallback, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import { useToasts } from "@puzzle/ui";

import { useFile } from "components/common/files/useFile";
import { AccountingRecordActivityType, AssociatedEntity, FileFragment } from "graphql/types";
import Analytics from "lib/analytics/analytics";
import useSelf from "components/users/useSelf";
import {
  SingleBillFragment,
  SingleBillFragmentDoc,
} from "components/dashboard/Accounting/Bills/graphql.generated";
import {
  FullJournalEntryFragment,
  FullJournalEntryFragmentDoc,
} from "components/dashboard/Accounting/ManualJournals/graphql.generated";
import {
  SingleLedgerReconciliationFragment,
  SingleLedgerReconciliationFragmentDoc,
} from "components/dashboard/Accounting/Reconciliation/LedgerReconciliation/graphql.generated";
import { isSupportedFileType } from "./utils";

type SupportedEntity =
  | SingleBillFragment
  | FullJournalEntryFragment
  | SingleLedgerReconciliationFragment;
type SupportedUploadEntity =
  | AssociatedEntity.Bill
  | AssociatedEntity.ManualJournalEntry
  | AssociatedEntity.LedgerReconciliation;

type UseAttachmentManagerProps = {
  entity?: SupportedEntity;
  uploadEntityType: SupportedUploadEntity;
  isEditor: boolean;
};

type UseAttachmentManagerReturn = {
  uploadFile: (files: File[]) => void;
  deleteFile: () => void;
  previewUrl?: string;
  isUploading: boolean;
  existingFile?: FileFragment;
};

export const useAttachmentManager = ({
  entity,
  uploadEntityType,
  isEditor,
}: UseAttachmentManagerProps): UseAttachmentManagerReturn => {
  const client = useApolloClient();
  const { self } = useSelf();
  const { toast } = useToasts();

  const getExistingFile = useCallback(() => {
    if (!entity) return undefined;

    if ("documents" in entity) {
      return entity.documents[0]?.file;
    }

    if ("storedFiles" in entity && entity.storedFiles) {
      return entity.storedFiles[0];
    }

    return undefined;
  }, [entity]);

  const existingFile = getExistingFile();

  const getCacheFragment = useCallback(() => {
    if (!entity) return;

    const configMap: Record<
      SupportedUploadEntity,
      {
        id: string;
        fragmentName: string;
        fragment:
          | typeof SingleBillFragmentDoc
          | typeof FullJournalEntryFragmentDoc
          | typeof SingleLedgerReconciliationFragmentDoc;
      }
    > = {
      [AssociatedEntity.Bill]: {
        id: `Bill:${entity?.id}`,
        fragmentName: "singleBill",
        fragment: SingleBillFragmentDoc,
      },
      [AssociatedEntity.ManualJournalEntry]: {
        id: `ManualJournalEntry:${entity?.id}`,
        fragmentName: "fullJournalEntry",
        fragment: FullJournalEntryFragmentDoc,
      },
      [AssociatedEntity.LedgerReconciliation]: {
        id: `LedgerReconciliation:${entity?.id}`,
        fragmentName: "singleLedgerReconciliation",
        fragment: SingleLedgerReconciliationFragmentDoc,
      },
    };

    const config = configMap[uploadEntityType];

    return {
      config,
      fragment: client.readFragment<typeof entity>(config),
    };
  }, [uploadEntityType, client, entity]);

  const updateFileCache = useCallback(
    (
      file: FileFragment,
      activityType:
        | AccountingRecordActivityType.FileUploaded
        | AccountingRecordActivityType.FileDeleted
    ) => {
      if (!entity) return;

      const cache = getCacheFragment();

      if (!cache) return;

      if (cache.fragment && self) {
        const createdAt = new Date().toISOString();
        const fileFieldName = "storedFiles" in cache.fragment ? "storedFiles" : "documents";
        const newFiles = "storedFiles" in cache.fragment ? [{ ...file }] : [{ file }];
        const activity =
          "activity" in cache.fragment
            ? {
                activity: [
                  ...cache.fragment.activity,
                  {
                    __typename:
                      activityType === AccountingRecordActivityType.FileUploaded
                        ? "AccountingRecordFileUploaded"
                        : "AccountingRecordFileDeleted",
                    id: createdAt,
                    type: activityType,
                    createdAt,
                    file,
                    createdByUser: {
                      id: self.id,
                      name: self.name,
                    },
                  },
                ],
              }
            : undefined;

        const currentFiles = cache.fragment[fileFieldName] || [];
        const updatedFiles =
          activityType === AccountingRecordActivityType.FileUploaded
            ? newFiles
            : currentFiles.filter((doc: any) => ("file" in doc ? doc.file.id : doc.id) !== file.id);

        client.writeFragment({
          ...cache.config,
          data: {
            ...cache.fragment,
            ...activity,
            [fileFieldName]: updatedFiles,
          },
        });
      }
    },
    [client, entity, self, getCacheFragment]
  );

  const { isUploading, onFiles, deleteFile } = useFile({
    entityId: entity?.id,
    entityType: uploadEntityType,
    onFileDeleted: ({ deleteFile }) => {
      Analytics.accountingRecordAttachmentDeleted({ fileId: deleteFile.fileId, uploadEntityType });
    },
    onUploadComplete: ([file]) => {
      if (!entity) return;
      updateFileCache(file, AccountingRecordActivityType.FileUploaded);
      toast({
        status: "success",
        message: "Attachment saved",
      });

      Analytics.accountingRecordAttachmentUploaded({
        id: entity.id,
        contentType: file.contentType,
        fileName: file.filename ?? "",
        fileSize: file.size,
        uploadEntityType: uploadEntityType,
      });
    },
    file: existingFile,
    skipOnFiles: !entity,
  });

  const handleDelete = useCallback(() => {
    if (existingFile) {
      deleteFile();
      updateFileCache(existingFile, AccountingRecordActivityType.FileDeleted);
    }
  }, [deleteFile, existingFile, updateFileCache]);

  const handleUpload = useCallback(
    (files: File[]) => {
      if (!isEditor) return;
      if (files[0] && !isSupportedFileType(files[0])) {
        toast({ status: "warning", message: "Please upload a PDF" });
        return;
      }

      onFiles(files);
    },
    [onFiles, isEditor, toast]
  );

  const previewUrl = useMemo(
    () => existingFile?.downloadInfo.signedUrl,
    [existingFile?.downloadInfo.signedUrl]
  );

  return {
    uploadFile: handleUpload,
    deleteFile: handleDelete,
    previewUrl,
    isUploading,
  };
};
